import React from "react";

export const Image = ({ title, largeImage, smallImage }) => {
  return (
    <div className="portfolio-item">
      <a href={largeImage} title={title} data-lightbox-gallery="gallery1">
        <img src={smallImage} className="img-responsive slider-image" alt={title} />
      </a>
    </div>
  );
};
