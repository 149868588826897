import React from "react";
import Slider from "react-slick";
import { Image } from "./image";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export const Gallery = (props) => {
  const settings = {
    dots: true,
    arrows:false,                // Показывать навигационные точки
    infinite: true,            // Зацикливание слайд-шоу
    speed: 500,                // Скорость прокрутки
    slidesToShow: 2,           // Количество слайдов, отображаемых за раз
    slidesToScroll: 1,         // Количество слайдов при прокрутке
    autoplay: true,   
    centerMode:true,
    centerPadding:'0px',         // Автоматическая прокрутка
    autoplaySpeed: 3000,       // Интервал между слайдами (в мс),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2, // 2 слайда на экранах до 1024px
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1, // 1 слайд на экранах до 768px
        },
      },
      {
        breakpoint: 480, // На экранах до 480px
        settings: {
          slidesToShow: 1, // Показываем 1 слайд
          slidesToScroll: 1,
          arrows: false, // Скрываем стрелки для удобства
        },
      },
    ],
  };

  return (
    <div id="portfolio" className="text-center">
        <div className="section-title">
          <h2>Gallery</h2>  
        </div>
        <div className="portfolio-items">
          {props.data ? (
            <Slider {...settings}>
              {props.data.map((d, i) => (
                <div key={`${d.title}-${i}`}>
                  <Image
                    title={d.title}
                    smallImage={d.smallImage}
                  />
                </div>
              ))}
            </Slider>
          ) : (
            "Loading..."
          )}
        </div>
      </div>
  );
};
