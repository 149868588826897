import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export const Header = (props) => {
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 768, // На экранах до 768px
        settings: {
          slidesToShow: 1, // Показываем 1 слайд
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480, // На экранах до 480px
        settings: {
          slidesToShow: 1, // Показываем 1 слайд
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <header id="header">
      <Slider {...sliderSettings}>
        {props.data && props.data.slides ? (
          props.data.slides.map((slide, index) => (
            <div key={index} className="intro">
              <div className="overlay">
                <img
                  src={slide.image}
                  alt={slide.title}
                  className="intro-image"
                />
                <div className="container">
                  <div className="row">
                    <div className="col-md-8 col-md-offset-2 intro-text">
                      <h1>{slide.title}</h1>
                      <p>{slide.paragraph}</p>
                      <a
                        href="#services"
                        className="btn btn-custom btn-lg page-scroll"
                      >
                        Learn More
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="intro">
            <h1>Loading</h1>
            <p>Loading</p>
          </div>
        )}
      </Slider>
    </header>
  );
};
