import React from "react";

export const Services = (props) => {
  return (
    <div id="services" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Our Services</h2>
        </div>
        <div className="row">
          {props.data
            ? props.data.map((d, i) => {
                // Проверяем, входит ли элемент в последние два
                const isLastTwo = i >= props.data.length - 2;
                return (
                  <div
                    key={`${d.name}-${i}`}
                    className={`col-md-${isLastTwo ? "6" : "4"}`} // Присваиваем col-md-6 для последних двух
                  >
                    <i className={d.icon}></i>
                    <div className="service-desc">
                      <h3>{d.name}</h3>
                      <p>{d.text}</p>
                    </div>
                  </div>
                );
              })
            : "loading"}
        </div>
      </div>
    </div>
  );
};
